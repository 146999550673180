import axios from 'utils/axios'
//  套餐促销信息表  /blade-promotion/combopromotion/list
export const comboList = () => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-promotion/combopromotion/list';
        axios.get(url, {
                //请求头配置  
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                //参数列表
                //    params:{memberStoreAddress:memberStoreAddress},
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

//查询A+B促销活动商品  /blade-promotion/front/ab/select-promotion-product-page
// 还有一个是查商品列表的，传入分类id，还有排序order，order为1：销量排序，2：价格排序，3：上架时间排序
export const promotionList = (datas) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-promotion/front/ab/select-promotion-product-page';
        axios.get(url, {
                //请求头配置  
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                //参数列表
                params: datas,
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}


// 查询A+B商品分类   /blade-promotion/front/ab/select-categorty-list
export const selectCategortyList = () => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-promotion/front/ab/select-categorty-list';
        axios.get(url, {
                //请求头配置  
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                //参数列表
                //    params:datas,
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}


//查询A+B促销活动商品详情  /blade-promotion/front/ab/select-ab-detail
export const selectABDetail = (datas) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-promotion/front/ab/select-ab-detail';
        axios.get(url, {
                //请求头配置  
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                //参数列表
                params: datas,
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

// 一元购活动列表  /api/blade-promotion/front/oneBuy/oneBuyPage

export const getBuyOneYuanList=()=>{
    return new Promise((resolve,reject)=>{
        let url ='/api/blade-promotion/front/oneBuy/oneBuyPage';
        axios.get(url,{
            //请求头配置  
            headers:{  'Content-Type':'application/x-www-form-urlencoded'},
           //参数列表
           params:{
            current: 1,
            size: 5,
           },
       })
        .then((data)=>{
            var result = data;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}



// 一元购促销活动详情   /blade-promotion/front/oneBuy/oneBuyDetail
export const oneBuyDetail=(datas)=>{
    return new Promise((resolve,reject)=>{
        let url ='/api/blade-promotion/front/oneBuy/oneBuyDetail';
        axios.get(url,{
            //请求头配置  
            headers:{  'Content-Type':'application/x-www-form-urlencoded'},
           //参数列表
           params:datas,
       })
        .then((data)=>{
            var result = data;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}



//app pc 生成预订单(单独成单)  /blade-order/order/generatePromotionOrder

export const PromotionOrder = (datas) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-order/order/generatePromotionOrder';
        axios({
                headers: {
                    'Content-Type': 'application/json',
                },
                url: url,
                method: "post",
                data: datas
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}




// 套餐活动列表接口 /blade-promotion/combopromotion/list   get
export const SetMealList = (datas) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-promotion/combopromotion/list';
        axios.get(url, {
                //请求头配置  
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                //参数列表
                params: datas,
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}


// 套餐活动详情  /blade-promotion/promotion/combo/detail
export const SetMealDetail = (datas) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-promotion/promotion/combo/detail';
        axios.get(url, {
                //请求头配置  
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                //参数列表
                params: datas,
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

// lihang 人气爆品信息表 OpenBooking   /blade-promotion/presellpromotion/selectPromotionProductPage

export const OpenBookingList = (datas) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-promotion/presellpromotion/selectPromotionProductPage';
        axios.get(url, {
                //请求头配置
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                //参数列表
                params: datas,
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}


// 限时购列表页    TimeBuy   /blade-promotion/flashSale/list
export const TimeBuyList = (datas) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-promotion/flashSale/list';
        axios.get(url, {
                //请求头配置  
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                //参数列表
                params: datas,
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}


//lihang 人气爆品  详情 /blade-promotion/presellpromotion/selectDetail
export const OpenBookingDetail = (datas) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-promotion/presellpromotion/selectDetail';
        axios.get(url, {
                //请求头配置  
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                //参数列表
                params: datas,
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

// 限时购    详情  /blade-promotion/flashSale/detail
export const TimeBuyDetail = (datas) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-promotion/flashSale/detail';
        axios.get(url, {
                //请求头配置  
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                //参数列表
                params: datas,
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}
// 小额包邮列表接口 /blade-business/product/freePostagePage   get
export const getNoPostageOfSome = (datas) => {
    return new Promise((resolve, reject) => {
      let url = '/api/blade-business/product/freePostagePage';
      axios.get(url, {
          //请求头配置  
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          //参数列表
          params: datas,
        })
        .then((res) => {
          var result = res.data;
          return resolve(result)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
  
  // 小额包邮商品详情   get
  export const freeMailDetail = (datas) => {
    return new Promise((resolve, reject) => {
      let url = '/api/blade-business/product/detail';
      axios.get(url, {
          //请求头配置  
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          //参数列表
          params: datas,
        })
        .then((res) => {
          return resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
  

// 限时拼团商品详情  /blade-promotion/flashGroup/detail
export const GetFlashGroupDetail = (datas) => {
    return new Promise((resolve, reject) => {
      let url = '/api/blade-promotion/flashGroup/detail';
      axios.get(url, {
          //请求头配置  
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          //参数列表
          params: datas,
        })
        .then((res) => {
          return resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }


// 积分商城列表页  /blade-business/pointsredeemproduct/list
export const IntegralMallList = (datas) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-business/pointsredeemproduct/list';
        axios.get(url, {
                //请求头配置  
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                //参数列表
                params: datas,
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}


// 积分商城详情页  // /blade-business/pointsredeemproduct/detail
export const IntegralMallDetail = (datas) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-business/pointsredeemproduct/detail';
        axios.get(url, {
                //请求头配置  
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                //参数列表
                params: datas,
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}



// 单独成单 积分  订单详情接口  
export const IntegralMallOrder = (datas) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-order/pointsorder/detail';
        axios.get(url, {
                //请求头配置  
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                //参数列表
                params: datas,
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

